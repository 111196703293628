import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import Frame from '../Frame/FrameContainer';
import { zenToHan, setValueByIndex, getParsedPhoneNumber } from '../../utils';
import { authTypeMap } from '../../constants';
import getPhoneToDisplay from '../../utils/get-phone-to-display';
import { requestOtpCode, submitOtpCode } from '../EmailUpdate/helpers';

import styles from '../OTP/OTPInput.scss';
import { updateEmailUpdatePairs } from '../../redux/modules/email-update';
import { emailReminderCodes } from '../../constants/error-codes';

import useRecaptcha from '../../hooks/use-recaptcha';
import { MIXPANEL_ACTION_CLICK, MIXPANEL_ACTION_FIELD_EDIT, MIXPANEL_ACTION_PAGE_VIEW, MixpanelHelpers } from '../../utils/mixpanel';

const PINCODE_DEBOUNCE = 15;
const DEFAULT_OTP = new Array(4).fill('');
const OTP_TIMEOUT = 3 * 60 * 1000;
const ERROR_3_ATTEMPS_PATH = `/error/${emailReminderCodes.OTP_MORE_THEN_3_ATTEMPS}`;
const MAX_RETRIES = 3;

const getRetryKey = (phone) => `otpRetryCount_${phone}`;

const EmailUpdateOTPInput = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const phone = useSelector(state => state.emailUpdate.phoneForEmailUpdate);
  const session = useSelector(state => state.emailUpdate.session);
  const reCapatchaToken = useSelector(state => state.emailUpdate.reCapatchaToken);

  const [otp, setOtp] = useState(DEFAULT_OTP);
  const [isWrongPincode, setIsWrongPincode] = useState(false);
  const [authType, setAuthType] = useState(authTypeMap.SMS);
  const [waiting, setWaiting] = useState(0);
  const inputs = useRef([]);
  const inputsWrapper = useRef(null);
  const timeoutRef = useRef(null);

  const { getToken } = useRecaptcha( process.env.REACT_APP_ENVIRONMENT === 'production'? 
    '6LcMVN8pAAAAAKwmtyjyLc-MC56mqgzxwYmzp4co' : '6LfAStYpAAAAAK4IruDhBd6xrZeVgVTtKX3lwEXV'
  );

  useEffect(()=>{
    MixpanelHelpers.trackAction({
      customPath: "Authentication Email Reminder",
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  },[])

  useEffect(() => {
    const retryKey = getRetryKey(phone);
    const retryCount = localStorage.getItem(retryKey) || 0;
    if (retryCount >= MAX_RETRIES) {
      history.push(ERROR_3_ATTEMPS_PATH);
      MixpanelHelpers.trackAction({
        customPath: "Forgot Email Address - Multiple Attempts Error",
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
      });
    }
    startOtpTimeout();
    return () => clearTimeout(timeoutRef.current);
  }, [history, phone]);

  const startOtpTimeout = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(handleOtpTimeout, OTP_TIMEOUT);
  };

  const onFocus = event => {
    event.preventDefault();
  };

  const onNumberInput = event => {
    const target = event.currentTarget;
    const convertedValue = zenToHan(target.value || '');
    if (!/^\d$/.test(convertedValue)) {
      return;
    }
    const _idx = +target.getAttribute('data-order');
    const pinCode = convertedValue.replace(otp[_idx], '');
    if (isWrongPincode && _idx === 0) {
      setIsWrongPincode(false);
    }
    const newOtp = setValueByIndex(_idx, pinCode, otp);
    setOtp(newOtp);
    if (newOtp.join('').length === 4) {
      onSubmit(newOtp.join(''));
    }
    const nextInputIdx = _idx + 1;
    if (inputs.current[nextInputIdx]) {
      inputs.current[nextInputIdx].focus();
    }

    MixpanelHelpers.trackAction({
      customPath: "Authentication Email Reminder",
      actionName: MIXPANEL_ACTION_FIELD_EDIT,
    });
  };

  const onKeyDown = event => {
    const _idx = +event.currentTarget.getAttribute('data-order');
    switch (event.key) {
      case 'E':
      case '+':
      case '-':
        event.preventDefault();
        break;
      case 'Enter':
        if (_idx === 3) {
          onSubmit(otp.join(''));
        }
        break;
      case 'Backspace':
      case 'Delete':
        if (event.currentTarget.value === '' && inputs.current[_idx - 1]) {
          setOtp(prevOtp => setValueByIndex(_idx - 1, '', prevOtp));
          inputs.current[_idx - 1].focus();
        } else {
          setOtp(prevOtp => setValueByIndex(_idx, '', prevOtp));
        }
        break;
      case 'ArrowLeft':
        if (inputs.current[_idx - 1]) {
          inputs.current[_idx - 1].focus();
        }
        break;
      case 'ArrowRight':
        if (inputs.current[_idx + 1]) {
          inputs.current[_idx + 1].focus();
        }
        break;
      default:
        break;
    }
  };

  const handleRetryCount = () => {
    const retryKey = getRetryKey(phone);
    const retryCount = +localStorage.getItem(retryKey) || 0;
    if (retryCount + 1 >= MAX_RETRIES) {
      history.push(ERROR_3_ATTEMPS_PATH);
      setTimeout(() => {
        localStorage.removeItem(retryKey);
      }, OTP_TIMEOUT);
      MixpanelHelpers.trackAction({
        customPath: "Forgot Email Address - Timeout Error",
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
      });
    } else {
      localStorage.setItem(retryKey, retryCount + 1);
    }
  };

  const handleOtpTimeout = () => {
    const ERROR_MAX_RETRIES_PATH = `/error/${emailReminderCodes.OTP_MAX_RETRIES}`;
    history.push(ERROR_MAX_RETRIES_PATH);
    MixpanelHelpers.trackAction({
      customPath: "Forgot Email Address - Timeout Error",
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  };

  const handleWrongPincode = () => {
    setIsWrongPincode(true);
    setOtp(DEFAULT_OTP);
    inputs.current[0].focus();
    handleRetryCount();
    MixpanelHelpers.trackAction({
      customPath: "Forgot Email Address - Invalid OTP",
      actionName: "Error",
    });
  };

  const onSubmit = async (completeOtp) => {
    const token = await getToken('CHECKOUT/REMIND_EMAIL');

    submitOtpCode({
      answer: completeOtp.trim(),
      reCapatchaToken: token,
      session,
    }).then(({ data }) => {
      if ((data.errors && !data.data) && data.errors.some(({ extensions }) => extensions.code === 500)) {
        history.push(`/error/${emailReminderCodes.OTP_UNAVAILABLE}`);
      } else if ((data.data && !data.errors) && data.data.respondToMyEmailReminderChallenge === null) {
        dispatch(
          updateEmailUpdatePairs({
            successfullyFinished: true,
            phoneForEmailUpdate: phone,
            reCapatchaToken: '',
            session: '',
          })
        );
        history.push('/login');
      } else {
        // Invalid OTP returns new session and we need to update it every time
        if (data 
          && data.data
          && data.data.respondToMyEmailReminderChallenge
        ) {
          dispatch(
            updateEmailUpdatePairs({
              session: data.data.respondToMyEmailReminderChallenge
            })
          );
        }
        
        handleWrongPincode();
      }
    });
  };

  const switchToVoice = async(event) => {
    event.preventDefault();
    const token = await getToken('CHECKOUT/REMIND_EMAIL');

    requestOtpCode({
      phoneNumber: phone,
      pinCodeDispatchMethod: authTypeMap.VOICE.toUpperCase(),
      reCapatchaToken: token,
    }).then(() => {
      setAuthType(authTypeMap.VOICE);
      waitVoiceCode();
    });
    MixpanelHelpers.trackAction({
      customPath: "Authentication Email Reminder",
      actionName: MIXPANEL_ACTION_CLICK,
      actionItem: "Receive OTP via automated voice call"
    });
  };

  const resendVoicePincode = async () => {
    if (waiting > 0) {
      return;
    }
    const token = await getToken('CHECKOUT/REMIND_EMAIL');

    requestOtpCode({
      phoneNumber: phone,
      pinCodeDispatchMethod: authTypeMap.VOICE.toUpperCase(),
      reCapatchaToken: token
    }).then(() => {
      waitVoiceCode();
    });
  };

  const waitVoiceCode = () => {
    startOtpTimeout();
    setWaiting(PINCODE_DEBOUNCE);
    setTimeout(waitingVoiceCode, 1000);
  };

  const waitingVoiceCode = () => {
    setWaiting(prevWaiting => {
      const now = prevWaiting - 1;
      if (now > 0) {
        setTimeout(waitingVoiceCode, 1000);
      }
      return now;
    });
  };

  const renderInputs = () => {
    return Array.from({ length: 4 }).map((_, i) => (
      <input
        id={`input_pin_${i}`}
        key={`input_pin_${i}`}
        type="tel"
        pattern="\d*"
        className={cx(styles.input, isWrongPincode && styles.error)}
        onInput={onNumberInput}
        onKeyDown={onKeyDown}
        name={`input_${i}`}
        data-order={i}
        ref={input => {
          inputs.current[i] = input;
        }}
        value={otp[i]}
        onChange={() => {}}
        max={9}
        min={0}
        onFocus={onFocus}
        autoFocus={i === 0 && authType === authTypeMap.SMS}
        autoComplete="off"
      />
    ));
  };

  const renderActionLink = () => {
    if (authType === authTypeMap.SMS) {
      return (
        <a href="#" onClick={switchToVoice}>
          自動音声案内で認証コードを受け取る
        </a>
      );
    } else if (authType === authTypeMap.VOICE) {
      const isWaiting = waiting > 0;
      return (
        <a href="#" onClick={resendVoicePincode} disabled={isWaiting}>
          自動音声案内で認証コードを再送する
          {waiting > 0 && ` — ${waiting}秒`}
        </a>
      );
    }
    return null;
  };

  const goBack = () => {
    setOtp(DEFAULT_OTP);
    history.goBack();
    MixpanelHelpers.trackAction({
      customPath: "Authentication Email Reminder",
      actionName: MIXPANEL_ACTION_CLICK,
      actionItem: "Edit mobile number"
    });
  };

  const phoneToDisplay = getPhoneToDisplay(getParsedPhoneNumber(phone));

  return (
    <Frame hasFooter={true} footerText="ヘルプ" footerOnClick={()=> { history.push('/help') }}>
      <div className={styles['scrollable-content']}>
        <span className={styles.title}>
          <b>{phoneToDisplay}</b>に届いた4桁の認証コードを入力してください
        </span>
        <div className={styles['inputs-wrapper']} ref={inputsWrapper}>
          {renderInputs()}
        </div>
        {isWrongPincode && (
          <span className={styles['error-message']}>認証コードに誤りがあります</span>
        )}
        <div
          className={cx(styles['voice-prompt'], authType === authTypeMap.SMS && styles['fade-in'])}
        >
          {authType === authTypeMap.SMS && (
            <>
              <span>認証コードが届かない場合</span>
              <br />
            </>
          )}
          {renderActionLink()}
        </div>
        <div className={styles['auth-info']}>
          <div>
            <span>{phoneToDisplay}</span>
          </div>
          <div>
            <a href="#" onClick={goBack}>
              編集
            </a>
          </div>
        </div>
      </div>
    </Frame>
  );
};

export default EmailUpdateOTPInput;
